import { render, staticRenderFns } from "./MobileDetails.vue?vue&type=template&id=1cc48f16&scoped=true"
import script from "./MobileDetails.vue?vue&type=script&lang=js"
export * from "./MobileDetails.vue?vue&type=script&lang=js"
import style0 from "./MobileDetails.vue?vue&type=style&index=0&id=1cc48f16&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cc48f16",
  null
  
)

export default component.exports