// 定义后端访问地址
// const backendUrls = {
//   url: 'http://localhost:19000',
//   wsUrl: 'ws://localhost:19000',
//   baseUrl: 'localhost:19000',
//   urlName: '-qxBase博客'
// };
const backendUrls = {
  url: 'https://blog.qxbase.com/api',
  wsUrl: 'wss://blog.qxbase.com/api',
  baseUrl: 'blog.qxbase.com',
  urlName: '-qxBase博客'
};
// const backendUrls = {
//   url: 'http://localhost:8080',
//   wsUrl: 'ws://localhost:8080',
//   baseUrl: 'localhost:8080',
//   urlName: '-qxBase博客'
// };

// 导出后端访问地址
export default backendUrls;